import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env';

@Injectable()
export class IconsService {
  constructor(private icon: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.icon.addSvgIcon(
      'down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/down.svg`)
    );

    this.icon.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/search.svg`)
    );

    this.icon.addSvgIcon(
      'bear',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/bear.svg`)
    );

    this.icon.addSvgIcon(
      'bear-popin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/bear-popin.svg`)
    );

    this.icon.addSvgIcon(
      'alert-mail',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/alert-mail.svg`)
    );

    this.icon.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/facebook.svg`)
    );

    this.icon.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/twitter.svg`)
    );

    this.icon.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/youtube.svg`)
    );

    this.icon.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/linkedin.svg`)
    );

    this.icon.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/instagram.svg`)
    );

    this.icon.addSvgIcon(
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/check.svg`)
    );

    this.icon.addSvgIcon(
      'bear-handup',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/bear-handup.svg`)
    );

    this.icon.addSvgIcon(
      'chat-bubbles',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/chat-bubbles.svg`)
    );

    this.icon.addSvgIcon(
      'cart',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/cart.svg`)
    );

    this.icon.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/trash.svg`)
    );

    this.icon.addSvgIcon(
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/home.svg`)
    );

    this.icon.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/download.svg`),
    );

    this.icon.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/close-popin.svg`),
    );

    this.icon.addSvgIcon(
      'scrolltop',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/scrolltop.svg`),
    );

    this.icon.addSvgIcon(
      'highlight_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/highlight_off.svg`),
    );

    this.icon.addSvgIcon(
      'speaker',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/speaker.svg`),
    );

    this.icon.addSvgIcon(
      'corporate_fare',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/corporate_fare.svg`),
    );

    this.icon.addSvgIcon(
      'thumbs_up_down_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/thumbs_up_down_outline.svg`),
    );

    this.icon.addSvgIcon(
      'push_pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/push_pin.svg`),
    );

    this.icon.addSvgIcon(
      'open_in_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/open_in_full.svg`),
    );

    this.icon.addSvgIcon(
      'settings-logiciel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/settings-logiciel.svg`),
    );

    this.icon.addSvgIcon(
      'settings-membres',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/settings-membres.svg`),
    );

    this.icon.addSvgIcon(
      'settings-societe',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/settings-societe.svg`),
    );

    this.icon.addSvgIcon(
      'sourcing-jobs_dark',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/sourcing-jobs_dark.svg`),
    );

    this.icon.addSvgIcon(
      'sourcing-jobs',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/sourcing-jobs.svg`),
    );

    this.icon.addSvgIcon(
      'sourcing-departements',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/sourcing-departements.svg`),
    );

    this.icon.addSvgIcon(
      'sourcing-parametres',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/sourcing-parametres.svg`),
    );

    this.icon.addSvgIcon(
      'sourcing-oldjobboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/sourcing-oldjobboard.svg`),
    );


    this.icon.addSvgIcon(
      'me-commrh',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/me-commrh.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-addprofil',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-addprofil.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-career-intern',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-career-intern.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-career-public',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-career-public.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-jobsites',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-jobsites.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-cv-externe',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-cv-externe.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-connect',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-connect.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-jobology',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-jobology.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-free-jobsites',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-free-jobsites.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-paying-jobsites',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-paying-jobsites.svg`),
    );

    this.icon.addSvgIcon(
      'diffusion-lus-nonrepondus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/diffusions/diffusion-lus-nonrepondus.svg`),
    );


    this.icon.addSvgIcon(
      'task_alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/task_alt.svg`),
    );

    this.icon.addSvgIcon(
      'task_invalid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/task_invalid.svg`),
    );

    this.icon.addSvgIcon(
      'id_card',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/id_card.svg`),
    );

    this.icon.addSvgIcon(
      'person_check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/person_check.svg`),
    );

    this.icon.addSvgIcon(
      'admin-societes',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/admin-societes.svg`),
    );

    this.icon.addSvgIcon(
      'admin-stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/admin-stats.svg`),
    );

    this.icon.addSvgIcon(
      'sourcing-channels',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/sourcing-channels.svg`),
    );

    this.icon.addSvgIcon(
      'sourcing_back',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/sourcing_back.svg`),
    );

    this.icon.addSvgIcon(
      'me_back',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/me_back.svg`),
    );

    this.icon.addSvgIcon(
      'formation-blog',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/formation-blog.svg`),
    );

    this.icon.addSvgIcon(
      'formation-podcast',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/formation-podcast.svg`),
    );

    this.icon.addSvgIcon(
      'formation-ressources',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/formation-ressources.svg`),
    );
  }
}